import Head from 'next/head'
import React, { useEffect, useState } from 'react'
import { AuthType } from 'src/types/shared/auth'
import ApprenticeLogin from './ApprenticeLogin'
import ApprenticeSignup from './ApprenticeSignup'
import { useTranslation } from 'react-i18next'
import { Loading } from '../basic'

interface Props {
  type?: AuthType
}

export const ApprenticeAuth: React.FC<Props> = ({ type }) => {
  const { i18n, ready } = useTranslation()
  const [authType, setAuthType] = useState<AuthType>(type ?? AuthType.LOGIN)
  const [localeInitialized, setLocaleInitialized] = useState(false)

  const handleAuthTypeChange = (type: AuthType) => {
    setAuthType(type)
  }

  useEffect(() => {
    const initializeLocale = async () => {
      if (!i18n.isInitialized && ready) {
        await i18n.init()
      }
      setLocaleInitialized(true)
    }

    initializeLocale()
  }, [i18n, ready])

  if (!localeInitialized) {
    return <Loading />
  }

  switch (authType) {
    case AuthType.LOGIN:
      return (
        <>
          <Head>
            <title>Candidate Log In - Qureos</title>
          </Head>
          <ApprenticeLogin onAuthTypeChange={handleAuthTypeChange} />
        </>
      )
    case AuthType.SIGNUP:
      return (
        <>
          <Head>
            <title>Candidate Sign Up - Qureos</title>
          </Head>
          <ApprenticeSignup onAuthTypeChange={handleAuthTypeChange} />
        </>
      )
    default:
      return <></>
  }
}

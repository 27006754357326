import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { GoogleDto, Photo } from '@/components/shared/misc'
import SocialLoginButtons from '@/components/shared/misc/SocialLoginButtons'
import QureosCarousel from '@/components/shared/basic/Carousel/QureosCarousel'

import { apprenticeCarouselDataBlue } from '@/utils/static-helpers/apprenticeCarouselData'
import { qureosRevisedLogo } from '@qureos/assets'
import { Input, Parser } from '@/components/shared/basic'
import { login, loginRedirect, notify } from '@/utils/lib'
import { signInWithGoogle } from 'src/services/apprentice/auth-apprentice'
import { SegmentEventType, UserRole } from '@qureos/types'
import { AuthType } from 'src/types/shared/auth'
import {
  CorporateLoginStep,
  CorporateLoginStepData
} from '@/components/corporate'
import { segmentTrackCallback } from '@/utils/segment'
import { Dictionary } from 'lodash'
import { useLogin } from 'src/hooks/useLogin'
import AuthTabs from '@/components/auth-toggle'

interface Props {
  onAuthTypeChange: (type: AuthType) => void
}

const ApprenticeLogin: React.FC<Props> = ({ onAuthTypeChange }) => {
  const router = useRouter()
  const apprenticeInstantJobId = router?.query?.apprenticeInstantJobId
  const verified = router?.query?.verified
  const passwordChanged = router?.query?.passwordChanged
  const inviteId: string = router?.query?.inviteId as string
  const referrer: string = router?.query?.referrer as string

  const redirectUrl = loginRedirect('get') as string
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loginData, setLoginData] = useState<CorporateLoginStepData>({
    isEmailNotValid: false,
    isPasswordNotValid: false,
    emailErrorMessage: '',
    passwordErrorMessage: ''
  })

  const [rememberMe, setRememberMe] = useState<boolean>(false)
  const [errors, setErrors] = useState<Dictionary<JSX.Element | string>>({})
  const { loginButton, onLogin, redirectUser } = useLogin()

  useEffect(() => {
    if (verified === 'success') {
      notify({
        message: 'Your email has been verified. Please login',
        type: 'success'
      })
    }
    if (passwordChanged === 'success') {
      notify({
        message: 'Your password has been changed. Please login',
        type: 'success'
      })
    }
  }, [verified, passwordChanged])

  useEffect(() => {
    if (inviteId) {
      notify({
        message: 'Please login to accept the invitation',
        type: 'success'
      })
    }
  }, [inviteId])

  const handleErrors = (errors: Dictionary<JSX.Element | string>) => {
    setLoginData(prev => {
      return {
        ...prev,
        isEmailNotValid: !!errors['email'],
        emailErrorMessage: errors['email'],
        isPasswordNotValid: !!errors['password'],
        passwordErrorMessage: errors['password']
      }
    })
  }

  const handleGoogleLogin = async (code: string): Promise<void> => {
    try {
      const signedUpForJob = !!redirectUrl && redirectUrl.startsWith('/jobs')
      const response = await signInWithGoogle(
        code,
        UserRole.STUDENT,
        referrer,
        !!signedUpForJob
      )

      const { data } = response

      if (!data.error) {
        login(data.token)

        setErrors(prev => {
          return {
            ...prev,
            social: null
          }
        })

        await redirectUser(data)
        segmentTrackCallback(SegmentEventType.LOGIN_WITH_GOOGLE_BUTTON, {
          dateTime: new Date(),
          fromUrl: router.asPath,
          redirectUrl: redirectUrl,
          role: data.role
        })
      } else {
        setErrors(prev => {
          return {
            ...prev,
            social: data.error
          }
        })
      }
    } catch (error) {
      setErrors(prev => {
        return {
          ...prev,
          social: (
            <Parser
              content={error?.response?.data?.message || 'Unable to connect'}
            ></Parser>
          )
        }
      })
    }
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex min-h-screen items-center justify-around bg-white py-1">
        <form
          className="flex flex-col w-96 lg:max-w-sm px-3 pt-2 mt-14"
          onSubmit={async e => {
            e.preventDefault()
            const errors = await onLogin(
              email,
              password,
              undefined,
              (apprenticeInstantJobId as string) || undefined
            )
            handleErrors(errors)
          }}
        >
          <div className="mb-4">
            <div className="w-full h-16 py-2">
              <Photo url={qureosRevisedLogo} title="Qureos" className="w-40" />
            </div>
          </div>
          <AuthTabs
            modeIndex="2"
            type="signin"
            redirectTo={
              apprenticeInstantJobId
                ? `?apprenticeInstantJobId=${apprenticeInstantJobId}`
                : ''
            }
          />
          <div className="flex flex-col items-start mb-4">
            <h2 className="font-extrabold text-3xl mb-2">Welcome back!</h2>
            <p className="text-gray-600">
              Sign in to your account to find new job matches. Don't have an
              account yet?
              <span
                onClick={() => {
                  onAuthTypeChange(AuthType.SIGNUP)
                  segmentTrackCallback(SegmentEventType.SIGNUP_LINK_CLICK, {
                    dateTime: new Date(),
                    fromUrl: router.asPath,
                    redirectUrl: redirectUrl,
                    role: UserRole.STUDENT
                  })
                }}
              >
                <span className="cursor-pointer text-qureosPrimary font-semibold ml-1">
                  Register here
                </span>
              </span>
            </p>
          </div>

          <CorporateLoginStep
            isApprentice
            fieldsClasses={['focus:border-qureosPrimaryLight']}
            loginData={loginData}
            onChange={(key, value) => {
              if (key === 'email') {
                setEmail(value)
              } else {
                setPassword(value)
              }
            }}
          />

          <div className="flex mt-5 justify-between my-6">
            <div className="flex items-center">
              <Input
                type="checkbox"
                className="h-4 w-4 checked:bg-qureosPrimary ring-qureosPrimary"
                checked={rememberMe}
                onChange={e => setRememberMe(e.target.checked)}
              />
              <div className="ml-2">Remember me</div>
            </div>

            <div className="flex items-center">
              <div className="ml-2">
                <Link href="/forgot-password">
                  <a className="text-qureosPrimary font-medium">
                    Forgot your password?
                  </a>
                </Link>
              </div>
            </div>
          </div>

          {loginButton()}
          <span className="mt-4">
            <SocialLoginButtons
              title="Sign in with "
              googleCallback={(dto: GoogleDto) => {
                handleGoogleLogin(dto.code)
              }}
              errors={errors}
            />
          </span>
          {/* <p className="mt-3 text-center text-sm font-normal  text-gray-600">
            Read our{' '}
            <a
              className="text-qureosPrimary font-semibold"
              href="https://congruous-lumber-861.notion.site/Qureos-Apprentice-Guides-and-FAQs-c26d1e1a0891488e8f5e9a8ead973cd2"
              target="_blank"
              rel="noopener noreferrer">
              FAQs
            </a>
          </p> */}
        </form>
        <div className="hidden lg:flex lg:visible">
          <QureosCarousel
            data={apprenticeCarouselDataBlue}
            showPagination={true}
            autoplay={true}
          />
        </div>
      </div>
    </div>
  )
}

export default ApprenticeLogin

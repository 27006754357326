import clsx from 'clsx'
import { FC, ReactNode } from 'react'
import { Field } from '@/components/shared/misc'
import { useTranslation } from 'react-i18next'

export interface CorporateLoginStepData {
  isEmailNotValid?: boolean
  isPasswordNotValid?: boolean
  emailErrorMessage?: ReactNode | string
  passwordErrorMessage?: ReactNode | string
}

type Props = {
  loginData: CorporateLoginStepData
  onChange: (key: string, value: string) => void
  fieldsClasses?: string[]
  isApprentice?: boolean
}

export const CorporateLoginStep: FC<Props> = ({
  loginData,
  onChange,
  fieldsClasses = [],
  isApprentice = false
}) => {
  const { t: commonLabel } = useTranslation('common')
  const {
    isEmailNotValid,
    isPasswordNotValid,
    emailErrorMessage,
    passwordErrorMessage
  } = loginData
  let emailClasses: string[] = [
    'font-normal',
    'rounded-md',
    'border',
    'border-solid',
    'py-2',
    'leading-tight',
    'px-3.5',
    'outline-0',
    'focus-visible:ring-0',
    isApprentice ? 'focus:border-qureosPrimary' : 'focus:border-qureosPrimary'
  ]

  let passwordClasses: string[] = [
    'font-normal',
    'rounded-md',
    'border',
    'border-solid',
    'py-2',
    'leading-tight',
    'px-3.5',
    'outline-0',
    'focus-visible:ring-0',
    isApprentice ? 'focus:border-qureosPrimary' : 'focus:border-qureosPrimary'
  ]

  emailClasses = emailClasses.concat(
    isEmailNotValid
      ? ['text-status-error', 'border-red-500']
      : ['placeholder-gray-500', 'text-gray-700', 'border-gray-300']
  )
  passwordClasses = passwordClasses.concat(
    isPasswordNotValid
      ? ['text-status-error', 'border-red-500']
      : ['placeholder-gray-500', 'text-gray-700', 'border-gray-300']
  )

  return (
    <div className="w-full flex flex-col">
      <div className="w-full mb-6">
        <Field
          type="email"
          labelClass="text-gray-700 font-medium"
          placeholder={commonLabel('REGISTERED_EMAIL')}
          className={clsx(emailClasses)}
          onChange={value => onChange('email', value)}
        />
        {isEmailNotValid && (
          <div className="text-status-error mt-2">{emailErrorMessage}</div>
        )}
      </div>

      <div className="w-full">
        <Field
          type="password"
          labelClass="text-gray-700 font-medium"
          placeholder={commonLabel('CONFIRM_PASSWORD')}
          className={clsx(passwordClasses)}
          onChange={value => onChange('password', value)}
        />
        {isPasswordNotValid && (
          <div className="text-status-error mt-2">{passwordErrorMessage}</div>
        )}
      </div>
    </div>
  )
}
